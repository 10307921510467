<template>
    <div class="o-section">
      <h2 class="section-title" v-if="sectionData && sectionData.ShowTitle">{{ sectionData.Title }}</h2>
        <slot />
    </div>
</template>

<script>
export default {
  props: {
    sectionData: {
      type: Object
      // required: true
    }
  }
}
</script>
